<template>
  <div class="container min-height-100">
    <header>
      <slot name="header"></slot>
      <!-- <router-link class="navbar-brand" to="/pages" exact>
        <span class="align-middle">pages</span>
      </router-link>
      /
      <router-link class="navbar-brand" to="/login" exact>
        <span class="align-middle">login</span>
      </router-link>
      /
      <router-link class="navbar-brand" to="/etape" exact>
        <span class="align-middle">etape</span>
      </router-link> -->
    </header>
    <main class="flex-column-gap-20 padding-top-10 padding-left-10 padding-right-10 scroll-y bg-fadestate min-height-100">
      <slot></slot>
    </main>
    <footer v-show="false">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<style>
footer {
  border-top: 1px solid #ccc;
  color: #666;
  font-size: 0.8em;
}
</style>
