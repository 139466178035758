<template>
  <div class="flex-middle-space-gap padding-20 bg-state bg-grey-04">
    <button class="xs bg-white no-border" target-modal="menu">
      <i class="fas fa-bars"></i>
    </button>
    <img :src="this.$logo" alt="COSI" style="width: 70px" />
    <button class="xs bg-white no-border" target-modal="help">
      <i class="fas fa-user-md-chat"></i>
    </button>
  </div>
</template>
