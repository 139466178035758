import api from "./api";
import TokenService from "./token.service";

class AuthService {
    login(username, password) {
        console.log('login from auth service');
        console.log(username, password);
        return api
            .post("/Security/Login", 
            {
                UserName: username,
                Password: password
            })
            .then((response) => {

                console.log('AuthService login result', response);
                if (response.data.token) {
                    TokenService.setUser(response.data);
                }

                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
    }

    // register({ username, email, password }) {
    //     return api.post("/auth/signup", {
    //         username,
    //         email,
    //         password
    //     });
    // }
}

export default new AuthService();