<template>
  <div class="view flex-column" :state="getAppStatus" id="appcontent" v-cloak>
    <BaseLayout>
      <template #header>
        <header-anonymous v-show="!currentUser"></header-anonymous>
        <header-authenticated v-show="currentUser"></header-authenticated>
      </template>

      <div v-if="!currentUser">
        <transition name="scale" mode="out-in">
          <login />
        </transition>
      </div>

      <!-- MAIN CONTENT -->

      <div v-else class="min-height-100 flexchild-100 flex-column">

      <transition name="scale" mode="out-in">
        
        <!-- <div v-if="useCalendarStore().getActiveEtape == null" class="flex-column-center-middle min-height-100  flexchild-100"> -->
          <div v-if="useCalendarStore().getActiveEtape == null" class="flex-column-center-middle min-height-100  flexchild-100">
          
            <h1 style="font-size:50px;">Bienvenue</h1>
          <h1 style="font-size:50px;margin-top: 50px"><img src="/img/cosi-splash.png"></h1>
            <h2 style="margin-top: 50px">
              <i class="fas fa-spinner fa-spin"></i>&nbsp;Chargement en cours
            </h2>
            <p>Si cet écran reste affcihé, veuillez fermer la fenêtre et vous reconnecter via un lien COSI svp.</p>
        </div>

        <div v-else>
          <transition name="scale" mode="out-in">
            <div class="flex-column-gap-10">
              <div
                :class="{
                  'nav-injection flex-middle-space': true,
                  ' cloak ': useCalendarStore().getActiveEtape != null,
                }"
              >
                <button
                  v-if="$route.path == '/etape' || $route.path == '' || $route.path == '/'"
                  class="xs"
                  @click="useCalendarStore().goPreviousEtape()"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>

                <button
                  v-if="$route.path == '/etape' || $route.path == '' || $route.path == '/'"
                  class="xs"
                  style="order: 3"
                  @click="useCalendarStore().goNextEtape()"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>

                <router-link to="/etape" class="flexchild-100">
                  <a
                    href=""
                    class="flexchild-100 flex-middle-center-gap-10 height-70 bg-grey-04 padding-10 rounded"
                  >
                    <div class="flexchild-100 mark flex-column-middle-center-gap-5">
                      <p class="weight-700">
                        {{ getActiveEtape.numEtape }}<sup class="font-xxs">e</sup> injection
                      </p>
                    </div>
                    <i class="icon far fa-calendar-day width-40"></i>
                    <p class="flexchild-100" v-if="!getActiveEtape.dateRDV">
                      <span class="weight-500 font-xs">Date cible</span><br />
                      <span class="weight-700">{{
                        $filters.formatDateLong(getActiveEtape.dateCible)
                      }}</span>
                    </p>
                    <p class="flexchild-100" v-if="getActiveEtape.dateRDV">
                      <span class="weight-500 font-xs">Date RDV</span><br />
                      <span class="weight-700">{{
                        $filters.formatDateLong(getActiveEtape.dateRDV)
                      }}</span>
                    </p>
                  </a>
                </router-link>
              </div>

              <router-view v-slot="{ Component }" v-if="currentUser">
                <transition name="scale" mode="out-in">
                  <component :is="Component"></component>
                </transition>
              </router-view>
            </div>
          </transition>
        </div>
      </transition>
      </div>
      <!-- /MAIN CONTENT -->

      <template #footer>
        <!-- FOOTER -->
        <div
          v-show="currentUser"
          class="flex-middle-space-gap position-relative padding-10 bg-grey-05"
        >
          <button class="flexchild-100 xs">
            <i class="far fa-calendar-alt width-40"></i>
            <span>Calendrier</span>
          </button>
          <button class="flexchild-100 xs">
            <i class="far fa-clipboard-list"></i>
            <span>Injections</span>
          </button>
        </div>
        <!-- /FOOTER -->
      </template>
    </BaseLayout>

    <!-- MODALES -->
    <div class="modal full from-top" id="help" is-opened="">
      <div class="overlay"></div>
      <div class="modal-container flex-column-gap-20">
        <div class="flex-middle-space-gap position-relative-z-10 padding-20 bg-grey-04">
          <p class="weight-700">
            <i class="icon fas fa-user-md-chat"></i>
            <span>Contact</span>
          </p>
          <button class="xs bg-white no-border close-modal" target-modal="test-modal">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div class="content flex-column-gap-20">
          <p>Si vous avez besoin d’aide, contactez votre équipe médicale :</p>
          <br />
          <span class="flex-center-middle">
            <i class="fas fa-phone"></i>&nbsp;
            <span> {{ servicePhoneNumber }} </span>
          </span>
          <br />
          <span class="flex-center-middle">
            <i class="fas fa-envelope"></i>&nbsp;
            <span> {{ serviceEmail }}</span>
          </span>

          <p><span style="color:green;">{{ contactMsg }}</span><span style="color:red;">{{ contactErrMsg }}</span></p>

          <div v-if="!hasContactRequest" class="content flex-column-gap-20">
            <p>Vous pouvez demander à être recontacté en cliquant ci-dessous :</p>
            <button
              type="button"
              class="button bg-green white hover-bg-darkgreen" 
              @click="contactRequest()">Être recontacté</button>
          </div>

          <div v-else class="content flex-column-gap-20">
            <p>Une demande de contact est en cours ! Pour l'annuler, cliquez ci-dessous :</p>
            <button
              type="button"
              class="button bg-orange white" 
              @click="contactRequest(true)">Annuler ma demande</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal full from-top"
      style="z-index: 101"
      id="conditions-utilisation"
      is-opened=""
    >
      <div class="overlay"></div>
      <div class="modal-container flex-column-gap-20">
        <div class="flex-middle-space-gap position-relative-z-10 padding-20 bg-grey-04">
          <p class="weight-700">
            <i class="icon fas fa-question-circle"></i>
            <span>Conditions d'utilisation</span>
          </p>
          <button
            class="xs bg-white no-border close-modal"
            target-modal="conditions-utilisation"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div class="content"><p>Bienvenue sur COSI ! <br />Les identifiants personnels et confidentiels que vous utilisez vous permettent de valider les étapes du calendrier établi avec votre médecin, et d'en informer votre équipe soignante. <br />En utilisant l'application, vous consentez à partager votre suivi avec l'équipe soignante de façon confidentielle et sécurisée.</p></div>
      </div>
    </div>
    <div class="modal full from-top" style="z-index: 101" id="vie-privee" is-opened="">
      <div class="overlay"></div>
      <div class="modal-container flex-column-gap-20">
        <div class="flex-middle-space-gap position-relative-z-10 padding-20 bg-grey-04">
          <p class="weight-700">
            <i class="icon fas fa-question-circle"></i>
            <span>Vie privée</span>
          </p>
          <button class="xs bg-white no-border close-modal" target-modal="vie-privee">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div class="content"><p>Aucune donnée n'est collectée mis à part votre date de connexion et les informations que vous envoyez explicitement, à savoir : <u>vos dates de rendez-vous</u> et <u>les dates de confirmation</u> des étapes de votre calendrier.</p>
        <p>Des cookies techniques sont utilisés pour maintenir votre session ouverte, ils sont effacés si vous vous déconnectez.</p></div>
      </div>
    </div>
    <div class="modal full from-top" style="z-index: 101" id="a-propos" is-opened="">
      <div class="overlay"></div>
      <div class="modal-container flex-column-gap-20">
        <div class="flex-middle-space-gap position-relative-z-10 padding-20 bg-grey-04">
          <p class="weight-700">
            <i class="icon fas fa-question-circle"></i>
            <span>À propos de COSI</span>
          </p>
          <button class="xs bg-white no-border close-modal" target-modal="a-propos">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div class="content"><p>COSI, votre <strong>Co</strong>mpagnon de <strong>S</strong>uivi des <strong>I</strong>njections, vous permet d'informer en toute sécurité, votre médecin de l'observance de votre traitement. En validant chacune des étapes, vous informez l'équipe soignante de votre respect du calendrier établi.</p></div>
      </div>
    </div>
    <div class="modal full from-top" style="z-index: 101" id="change-pw" is-opened="">
      <div class="overlay"></div>
      <div class="modal-container flex-column-gap-20">
        <div class="flex-middle-space-gap position-relative-z-10 padding-20 bg-grey-04">
          <p class="weight-700">
            <i class="icon fas fa-question-circle"></i>
            <span>Changer votre mot de passe</span>
          </p>
          <button class="xs bg-white no-border close-modal" target-modal="change-pw">
            <i class="fas fa-times"></i>
          </button>
        </div>  

        <div class="content flex-column">
          <label for="oldPassword">Mot de passe actuel :</label>
          <input inputmode="numeric" pattern="[0-9]{8}" type="password" id="oldPassword" v-model="oldPassword" @input="validatePassword()" />
          <label for="newPassword">Nouveau mot de passe :</label>
          <input inputmode="numeric" pattern="[0-9]{8}" type="password" id="newPassword" v-model="newPassword" @input="validatePassword()" />
          <label for="cnfPassword">Confirmation nouveau mot de passe :</label>
          <input inputmode="numeric" pattern="[0-9]{8}" type="password" id="cnfPassword" v-model="cnfPassword" @input="checkCnfPassword()" />
          <p v-if="!pwValid && !(oldPassword=='' && newPassword=='')">Mots de passe à 8 chiffres svp.</p>
          <p>{{ chPwMsg }}</p>
          <p v-if="!pwCnfValid">Les nouveaux mots de passe ne correspondent pas !</p>
          
          <button
            type="button"
            :disabled="!pwValid || !pwCnfValid"
            class="button bg-green white hover-bg-darkgreen" 
            :class="{disabled: !pwValid || !pwCnfValid}" 
            @click="changePassword()">Changer mon mot de passe</button>
          
          <button v-if="chPwdOk" type="button" class="button bg-grey-03 grey-00 close-modal" target-modal="change-pw">Fermer</button>

        </div>
      </div>
    </div>
    <div class="modal from-top" id="menu" is-opened="">
      <div class="overlay"></div>
      <div class="modal-container flex-column-gap-10">
        <div class="flex-middle-space-gap padding-20 bg-grey-04">
          <button class="xs bg-white no-border close-modal" target-modal="test-modal">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="flex-column-gap-10 padding-10">
          <button
            type="button"
            onclick="localStorage.removeItem('user');location.href='/';"
          >
            <span>Se déconnecter</span>
          </button>
          <button type="buton" target-modal="conditions-utilisation">
            <span>Conditions d’utilisation</span>
          </button>
          <button type="buton" target-modal="vie-privee">
            <span>Vie privée</span>
          </button>
          <button type="buton" target-modal="change-pw">
            <span>Changer de mot de passe</span>
          </button>          <button type="buton" target-modal="a-propos">
            <span>À propos de COSI</span>
          </button>
        </div>
      </div>
    </div>
    <!-- /MODALES -->
  </div>
</template>
<script setup>
// import { useAuthStore } from "./stores/auth.store";
// import { useCalendarStore } from "./stores/calendar";

// const calendarStore = useCalendarStore();
// const authStore = useAuthStore();
//window.stores = { authStore, calendarStore };

import { storeToRefs } from "pinia";
import { useCalendarStore } from "./stores/calendar";
// const { fetchCalendar } = useCalendarStore();

const { fetchCalendar, etapes, loading, error, getActiveEtape } = storeToRefs(
  useCalendarStore()
);
useCalendarStore().fetchCalendar();
</script>

<script>
// import EventBus from "./common/EventBus";
import BaseLayout from "./components/BaseLayout.vue";
import HeaderAnonymous from "./components/HeaderAnonymous.vue";
import Login from "./components/Login.vue";
import Etape from "./components/Etape.vue";
import RdvForm from "./components/RdvForm.vue";
import HeaderAuthenticated from "./components/HeaderAuthenticated.vue";
import { useAuthStore } from "./stores/auth.store";
import api from "./services/api";
import { stringifyQuery } from "vue-router";
import { toHandlers } from "vue";

export default {
  name: "App",
  setup() {},
  async created() {},
  data() {
    return {
      //user: null,
      servicePhoneNumber:(localStorage.getItem("user"))?JSON.parse(localStorage.getItem("user")).servicePhoneNumber:"",
      serviceEmail:(localStorage.getItem("user"))?JSON.parse(localStorage.getItem("user")).serviceEmail:"",
      hasContactRequest:(localStorage.getItem("user"))?JSON.parse(localStorage.getItem("user")).hasContactRequest:"",
      isUserLoggedIn: true,
      getAppStatus: "default",
      //cid:(localStorage.getItem("user"))?JSON.parse(localStorage.getItem("user")).cid:"", //mais on ne l'a pas... TODO.
      pwValid : false,
      pwCnfValid : true,
      oldPassword : '',
      newPassword : '',
      cnfPassword : '',
      chPwMsg : '',
      chPwdOk : false,
      contactMsg : '',
      contactErrMsg : '',
      contactOk :false, 
    };
  },
  components: {
    BaseLayout,
    HeaderAnonymous,
    HeaderAuthenticated,
    Login,
    Etape,
    RdvForm
  },
  computed: {
    currentUser() {
      const authStore = useAuthStore();
      return authStore.status.loggedIn;
    },
  },
  mounted() {
    console.log("******", this.$store);
    // EventBus.on("logout", () => {
    //   this.logOut();
    // });
  },
  beforeUnmount() {
    // EventBus.remove("logout");
    this.$router.push({ name: "Login" });
  },
  watch: {
    $route: "onRouteChange",
  },
  methods: {
    setAppStatus(state) {
      this.getAppStatus = state;
    },
    login() {
      // this.$auth.loginRedirect()
    },
    async onRouteChange() {
      // await this.refreshUser()
    },
    async refreshUser() {
      //this.user = await this.$auth.getUser()
    },
    logOut() {
      // this.$store.dispatch("auth/logout");
      const authStore = useAuthStore();
      authStore.logout();
      location.href = "/";
      // location.href = "/login";
    },
    contactRequest(cancel){

        let args = false;
        if(cancel === true) args = true; else args = true;

        api
        .post("/Security/ContactRequest", args)
        .then((json) => {
          console.log("axios get result", json);
          if(json.data.success == true) {
            this.hasContactRequest = json.data.hasContactRequest;
            this.contactMsg = json.data.message;
          } else {
            this.contactErrMsg = json.data.message;
          }
          return;
        });
    },
    changePassword(){
      this.chPwMsg = "";
      this.chPwOk = false;
      if(this.pwValid) {
        api
        .post("/Security/ChangeUserPassword", {
            oldp: this.oldPassword,
            newp: this.newPassword
        })
        .then((json) => {
          console.log("axios get result", json);
          this.chPwMsg = json.data.message;
          if(json.data.success == true) {
            this.chPwdOk = true ;
            this.cnfPassword = "";
            this.oldPassword = "";
            this.newPassword = "";
            this.pwValid = false;
            this.pwCnfValid = true;
          }
          return;
        });
      }
    },
    validatePassword() {
        const digitsRegex = /^\d{8}$/;
        if(digitsRegex.test(this.oldPassword) && digitsRegex.test(this.newPassword)) {
          this.pwValid = true;
          this.pwCnfValid = this.cnfPassword == '' || (this.newPassword == this.cnfPassword); //pour éviter l'activation du bouton qd cnf est vide.
        }
    },
    checkCnfPassword() {
        this.pwCnfValid = this.cnfPassword == '' || (this.newPassword == this.cnfPassword);
    },
    test() {
      api
        .post("/test", {
            a: '0',
            b: '0'
        })
        .then((json) => {
          console.log("axios get result", json);
          let res = json.data;
          this.loading = false;
          return;
        });
    },
    updateAppStatus(status) {},
  },
};
</script>

<style>
[v-cloak] {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
  opacity: 1;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 1;
  transform: scale(0.9);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}
</style>
