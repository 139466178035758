import axios from "axios";
import TokenService from "./token.service";
import { useAuthStore } from "../stores/auth.store";


const instance = axios.create({
    //baseURL: 'https://stg-api.my-co.si/api',
    //baseURL: 'https://api.my-co.si/api',
    baseURL: process.env.VUE_APP_APIENPOINT,
    headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("user"))?.token
    },
    withCredentials : true,
    validateStatus: function (status) {
        return status >= 200 && status != 400 && status != 401 && status < 500;
    }
});


instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            // config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
        return config;
    },
    
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;


        if (originalConfig.url !== "/Security/Login" && err.response) {

            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                console.log('4 Access Token was expired');
                console.log('TokenService.getLocalAccessToken()=', TokenService.getLocalAccessToken());
                console.log('TokenService.getLocalRefreshToken()=', TokenService.getLocalRefreshToken());

                const authStore = useAuthStore();
                // // try {
                    const rs = await instance.post("/Security/RefreshToken", {
                        accessToken: TokenService.getLocalAccessToken(),
                        refreshToken: TokenService.getLocalRefreshToken(),
                    })
                    .then(
                        res => {console.log("RefreshToken responded." )}, 
                        error => {
                            console.log("RefreshToken was expired.");
                            authStore.status.login = false;
                            authStore.logout();
                            //
                            return instance(originalConfig);
                            //return Promise.reject(error);
                    });

                    console.log('accessToken rs.data=', rs.data);

                    //authStore.dispatch('/Security/RefreshToken', accessToken);
                    authStore.refreshToken(rs.data);
                    TokenService.updateLocalAccessToken(rs.data.token);
                    console.log('Access Token updated');

                    return instance(originalConfig);
                // // } catch (_error) {
                // //     return Promise.reject(_error);
                // // }
            } 
        }

        return Promise.reject(err);
    }
);


// // Add a request interceptor
// axios.interceptors.request.use(
//     function (config) {
//         // Do something before request is sent

//         useAuthStore().$reset();

//         return config;
//     },
//     function (error) {
//         // Do something with request error
//         return Promise.reject(error);
//     }
// );


// // Add a response interceptor
// axios.interceptors.response.use(
//     function (response) {
//         // Any status code that lie within the range of 2xx cause this function to trigger
//         // Do something with response data
//         return response;
//     },
//     function (error) {
//         // Any status codes that falls outside the range of 2xx cause this function to trigger
//         // Do something with response error
//         switch (error.response.status) {
//             case 401:
//                 localStorage.removeItem("user");
//                 window.location.reload();
//                 break;
//             case 403:
//             case 404:
//                 router.push({
//                     name: "error",
//                     props: {
//                         error: {
//                             message: error.response.data.message,
//                             status: error.status,
//                         },
//                     },
//                 });
//                 break;
//             case 422:
//                 useAuthStore().$state = error.response.data;
//                 break;
//             default:
//                 console.log(error.response.data);
//         }

//         return Promise.reject(error);
//     }
// );


export default instance;