<template>
    <div v-if="isChpwd">
        <transition name="scale" mode="out-in">
            <RequestChPwd 
            :cid='this.username'
            @done="doneForgotPassword"
             />
        </transition>
    </div>
    <div v-else class="post">
        <div v-if="loading" class="loading"></div>

        <!-- <p class="text-center weight-600">
            Pour vous connecter, veuillez saisir votre code confidentiel
        </p> -->

        <form class="flex-column-gap">
            <input type="text" :value="username" class="text-center" v-show="false" />
            <input type="password"
                   :value="accessCode"
                   readonly
                   inputmode="decimal"
                   placeholder="Votre code d'accès"
                   max="999999999"
                   class="text-center" style=" min-height:50px;" />

            <div class="keyboard flex-column">
                <div class="key-row flex-wrap">
                    <div class="key" v-for="digit in keys" v-bind:key="digit">
                        <a class="button fullwidth" @click.stop="press(digit)">
                            <span class="no-padding">{{ digit }}</span>
                        </a>
                    </div>
                    <div class="key">
                        <a class="button fullwidth" @click="clear()">
                            <span class="no-padding orange"><i class="fa fa-arrow-left"></i></span>
                        </a>
                    </div>
                    <div class="key">
                        <a class="button fullwidth" @click="clear('all')">
                            <span class="no-padding red"><i class="fa fa-times"></i></span>
                        </a>
                    </div>
                </div>
            </div>



            <button type="button"
                    class="button bg-green white hover-bg-darkgreen"
                    :disabled="accessCode.length == 0"
                    @click="this.login()">
                <i class="far fa-sign-in"></i>
                <span>Me connecter</span>
            </button>

            <div v-show="this.hasError"
                 class="flex-middle-gap padding-10 rounded border-red hover-bg-fadered">
                <div class="flex-column-middle-center-gap-5 width-60 red">
                    <i class="fas fa-exclamation font-xl"></i>
                </div>
                <p class="flexchild-100">
                    {{ errorMessage }}
                </p>
            </div>
            <div class="flex-middle-center-gap padding-5 rounded bg-grey-04">
                <label for="checkbox001" class="checkbox">
                    <input id="checkbox001" type="checkbox" checked="checked" />
                    <i class="fas fa-check"></i>
                    <span class="font" style="font-size:12px">J’accepte les conditions générales d’utilisation</span>
                </label>
                <i id="cgu-trigger" class="fas fa-info-circle font-xl grey-01 hover-blue"></i>
            </div>
            <button type="button" @click="forgotPassword()" class="xs initialcase bg-grey-05 no-border grey-00">
                <span class="weight-500"><u>J'ai oublié mon mot de passe</u></span>
            </button>
        </form>
    </div>
</template>

<script lang="js">
    import router from '@/router/router';
    import { defineComponent } from 'vue';
    import { useAuthStore } from "../stores/auth.store";
    import RequestChPwd from '../components/RequestChPwd.vue';



    export default defineComponent({
        data() {
            return {
                loading: false,
                post: null,
                username: '',
                accessCode: "",
                keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
                authStore: null,
                hasError: false,
                errorMessage: '',
                isChpwd: false
            };
        },
        components: {
            RequestChPwd
        },
        mounted() {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            this.username = params.cid; 

            //If a guid is found in the first path segment we use it preferentially as cid.
            const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            const matches = regex.exec(window.location.pathname.substring(1).split('/')[0]);
            if(matches)
                this.username = matches[0];

            //console.log('this.username=', this.username);   

        },
        created() {
            // fetch the data when the view is created and the data is
            // already being observed
            this.fetchData();
            //this.$parent.$emit('update:layout', LoginHeader);

            this.authStore = useAuthStore();
        },
        watch: {
            // call again the method if the route changes
            '$route': 'fetchData'
        },
        methods: {
            fetchData() {
                this.post = null;
                this.loading = true;

            },
            press(key) {
                this.accessCode = `${this.accessCode}${key}`;
            },
            clear(type) {
                if (type === "all") this.accessCode = "";
                else this.accessCode = this.accessCode.substring(0, this.accessCode.length - 1);
            },
            login() {
                let ref = this;
                ref.hasError = false;

                this.authStore.login(this.username, this.accessCode)
                    .catch(function (error) {
                        ref.hasError = true;
                        ref.errorMessage = error;//.data;//.message;

                        if (error.response) {
                            // Request made and server responded
                            console.log('Error', error.message);
                            if (!error.response.data.errors) { //Erreur de credentials

                                ref.errorMessage = error.response.data; 

                            } else { //Erreurs de validation
                                
                                ref.errorMessage = error.response.data.errors; 

                                if(error.response.data.errors.UserName) {
                                    ref.errorMessage = '' + error.response.data.errors.UserName; //bidon pour forcer la conversion du tableau de chaînes en chaîne.
                                }
                                if(error.response.data.errors.Password) {
                                    ref.errorMessage = '' + error.response.data.errors.Password; //le bouton est désactivé si pwd est vide.
                                }

                            }
                            // console.log(error.response.data);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }

                    });
            },
            forgotPassword() {
                this.isChpwd = true;
            },
            doneForgotPassword() {
                this.isChpwd = false;
            }
        },
    });
</script>

<style>
    .keyboard {
        padding: 0;
    }

        .keyboard .key-row {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
        }

            .keyboard .key-row .key {
                flex: 0 1 100%;
                padding: 0;
            }
</style>
