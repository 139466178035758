// import Vue from "vue";
// import VueRouter from "vue-router";
import Login from '../components/Login.vue'
import Etape from '../components/Etape.vue'
import Error from '../views/Error.vue'
import RdvForm from '../components/RdvForm.vue'
import TraitementForm from '../components/TraitementForm.vue'
import InjectionForm from '../components/InjectionForm.vue'
import { createRouter, createWebHistory } from 'vue-router'
// import { useAuthStore } from "../stores/auth.store";

// Vue.use(VueRouter);

const routes = [
    { path: '/pages' },
    {
        path: '/login', component: Login,
        meta: { transition: 'scale-enter' }
    },
    {
        path: '/', component: Etape,
        meta: { transition: 'scale-enter' }
    },
    {
        path: '/etape', component: Etape,
        meta: { transition: 'scale-enter' }
    },
    {
        path: '/rdv', component: RdvForm,
        meta: { transition: 'scale-enter' }
    },
    {
        path: '/traitement', component: TraitementForm,
        meta: { transition: 'scale-enter' }
    },
    {
        path: '/injection', component: InjectionForm,
        meta: { transition: 'scale-enter' }
    },
    {
        path: '/error', component: Error,
        meta: { transition: 'scale-enter' }
    }
];

// const router = new VueRouter({
//     mode: "history",
//     base: process.env.BASE_URL,
//     routes,
// });


// export const router = createRouter({
//     history: createWebHistory(),
//     linkActiveClass: 'active',
//     routes: routes,
// });

const router = createRouter({
    history: createWebHistory(),
    routes,
});
// router.beforeEach(async (to) => {
//     // redirect to login page if not logged in and trying to access a restricted page
//     const publicPages = ['/login'];
//     const authRequired = !publicPages.includes(to.path);
//     const auth = useAuthStore();

//     if (authRequired && !auth.user) {
//         auth.returnUrl = to.fullPath;
//         return '/login';
//     }
// });

export default router;