<script setup>
import { storeToRefs } from "pinia";
import { defineComponent, defineEmits } from 'vue';
// import { useAuthStore } from "../stores/auth.store";
import { useCalendarStore } from "../stores/calendar";
import api from "../services/api";
import moment from "moment";
const { etapes, loading, error, getActiveEtape, getActiveEtapeRdvState } = storeToRefs(useCalendarStore());
const emit = defineEmits(['setAppStatus'])
console.log(emit);
emit('setAppStatus', getActiveEtapeRdvState)
// onMounted(() => {$emit('setAppStatus()');});
</script>

<template>
  <div class="post" :state="getActiveEtapeActeState">
    <div v-if="loading" class="loading">loading</div>
    <p v-if="error">{{ error.message }}</p>



    <!-- <div class="flex-middle-gap-20 margin-left-10  margin-bottom-10 margin-right-10">
      <i class="icon fas fa-syringe bg-state white"></i>
      <h1 class="flexchild-100">
        <span class="weight-600">Confirmez votre injection</span>
      </h1>
    </div> -->

    <form class="flex-column-gap padding-10 rounded-30 bg-white shadow">

      <p class="margin-top-10 padding-10 font-xl"><i class="icon fas fa-syringe bg-state white"></i> Confirmez que vous avez bien eu l'injection prévue le <span class="text-bold underline">{{
        $filters.formatDate(getActiveEtape?.dateRDV) }}</span></p>

      <button 
        v-if="getActiveEtape?.statutEtapeIDEid == 0"
        type="button"
        class="flex-center btn white bg-darkstate bg-blue"
        successmessage="Action confirmée !"
        successaction="changeStateToComplete"
        @click="setInjection(true)">
        <span class="text">Confirmer</span>
      </button>


      <router-link to="/etape">
        <button 
          v-if="getActiveEtape?.statutEtapeIDEid == 0"
          type="button"
          :class="{
          'btn white back': true,
          'bg-grey-green': isSuccess,
          'bg-grey-03': !isSuccess,
        }"
          successmessage="Action annulée !" successaction="" style="width: 100%">
          <!-- <span class="drag-rail">
                      <i class="drag-handle far fa-bars"></i>
                    </span> -->
          <span class="text grey-00 margin-10 padding-10" v-show="!isSuccess">Plus tard</span>
          <span class="text green margin-10 padding-10" v-show="isSuccess">Retour</span>
        </button>
      </router-link>

      
      <div class="flex-end">
        <button v-if="getActiveEtape?.statutEtapeIDEid == 0" class="xs btn white bg-darkstate" type="button" @click="setInjection(false)">
          <i class="fa fa-times"></i> <span>Annuler la confirmation</span>
        </button>
      </div>


    </form>




  </div>
</template>



<script>

export default defineComponent({
  data() {
    return {
      loading: false,
      post: null,
      calendar: null,
      currentEtape: null,
      dateRdv: '',
      hasError: false,
      errorMessage: '',
      isSuccess: false,
      calendarStore:null
    };
  },
  components: {
  },
  computed: {
  },
  created() {
    this.calendarStore = useCalendarStore();
  },
  mounted() {

    //document.getElementsByTagName('main').setAttribute('state', this.$filters.getCssStatus(useCalendarStore().getActiveEtape?.actes[1].status.state));
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData',
    'calendar': 'setCurrentEtape'
  },
  methods: {
    setInjection(hasInjectionGot) {
      useCalendarStore().setInjection(hasInjectionGot);
      this.isSuccess=true;
      this.$router.push({ path: '/etape' });
    }
  },
});
</script>