import { defineStore } from "pinia";
// import { fetchWrapper, router } from '../helpers/fetchWrapper';
// import { APISettings } from '../api/config';
import AuthService from '../services/auth.service';
import TokenService from "../services/token.service";
// import { router } from "../router/";

// const baseUrl = `${APISettings.baseURL }`;

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, returnUrl: null, user: user } //TODO: Fix user:user fait planter
    : { status: { loggedIn: false }, returnUrl: null, user: null };

console.log('user : ', user);
console.log('initialState : ', initialState);

export const useAuthStore = defineStore("user", {
    namespaced: true,
    state() {
        console.log('initialState : ', initialState);
        return initialState;
    },
    actions: {
        login(user, password) {
            console.log('login from auth store');
            console.log(user, password);

            return AuthService.login(user, password).then(
                response => {
                    //commit('loginSuccess', user);

                    // router.push(this.returnUrl || '/etape');
                    location.href = '/';
                    return Promise.resolve(response);
                },
                error => {
                    //commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout(/*{ commit }*/) {
            // console.log(commit);
            localStorage.removeItem("user");
            initialState.status.loggedIn = false;
            AuthService.logout();
            //commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    console.log(commit);
                    //commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    //commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        refreshToken(accessToken) {

            // this.refreshToken(this.state, accessToken);
            TokenService.setUser(accessToken);
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        refreshToken(state, accessToken) {
            state.status.loggedIn = true;
            state.user = { ...state.user, accessToken: accessToken };
            TokenService.setUser(accessToken);

        }
    }
});
