<script setup>
import { storeToRefs } from "pinia";
import { defineComponent, defineEmits } from "vue";
// import { useAuthStore } from "../stores/auth.store";
import { useCalendarStore } from "../stores/calendar";
import api from "../services/api";
import moment from "moment";
const { etapes, loading, error, getActiveEtape, getActiveEtapeRdvState } = storeToRefs(
  useCalendarStore()
);

console.log("getActiveEtape", getActiveEtape);

const emit = defineEmits(["setAppStatus"]);
console.log(emit);
emit("setAppStatus", getActiveEtapeRdvState);

// onMounted(() => {
//   // $emit('setAppStatus()');
//   this.dateRdv = getActiveEtape.dateRDV;
//   });
</script>
<template>
  <div
    class="post"
    :state="$filters.getCssStatus(useCalendarStore().getActiveEtapeRdvState)"
  >
    <div v-if="loading" class="loading">loading</div>
    <p v-if="error">{{ error.message }}</p>

    <!-- <div class="flex-middle-gap-20 margin-left-10 margin-bottom-10 margin-right-10">
      <i class="icon fas fa-home-alt bg-state white"></i>
      <h1 class="flexchild-100">
        <span class="weight-600">Prenez rendez-vous</span>
      </h1>
    </div> -->

    <form class="flex-column-gap-20 padding-10 rounded-30 bg-white shadow">
      <p class="margin-top-10 padding-10 font-xl">
        <i class="icon fas fa-home-alt bg-state white"></i> Indiquez la date du rendez-vous pris avec votre infirmière&nbsp;:
      </p>
      <input
        type="date"
        :value="$filters.formatDateInput(dateRdv)"
        @change="updateRdvValue"
        class="text-center"
      />
      <p :class="{
        ' text-center ' : true,
        ' red ' : !isDateValid && isDateSet
      }"
        >
        entre le
        <span class="text-bold underline">{{
          $filters.formatDate(getActiveEtape?.cibleMin)
        }}</span>
        et le
        <span class="text-bold underline">{{
          $filters.formatDate(getActiveEtape?.cibleMax)
        }}</span>
      </p>

      <div v-if="!isDateSet" class="flex-middle-gap padding-10 rounded border-red hover-bg-fadered">Veuillez indiquer une date svp.</div>
      <div v-if="!isDateValid && isDateSet" class="flex-middle-gap padding-10 rounded border-red hover-bg-fadered">Date hors plage !</div>

      <div
        v-show="hasError"
        class="flex-middle-gap padding-10 rounded border-red hover-bg-fadered"
      >
        <div class="flex-column-middle-center-gap-5 width-60 red">
          <i class="fas fa-exclamation font-xl"></i>
        </div>
        <p class="flexchild-100">
          {{ errorMessage }}
        </p>
      </div>
      <button
        type="button"
        v-if="getActiveEtape?.statutEtapeIDEid == 0"
        @click="setRdv()"
        :class="{
          ' bg-blue ' : true,
          // ' bg-red ' : !isDateValid,
          ' btn white bg-darkstate ': true, 
          ' disabled ': !isDateValid
          }"
        successmessage="Action confirmée !"
        successaction="changeStateToComplete"
        :disabled="!isDateValid"
      >
        <!-- <span class="drag-rail">
                <i class="drag-handle far fa-bars"></i>
              </span> -->
        <span class="text">Confirmer</span>
      </button>
      <router-link to="/etape">
        <button
        v-if="getActiveEtape?.statutEtapeIDEid == 0"
          type="button"
          :class="{
            'btn white back': true,
            'bg-grey-green': isSuccess,
            'bg-grey-03': !isSuccess,
          }"
          successmessage="Action annulée !"
          successaction=""
          style="width: 100%"
        >
          <!-- <span class="drag-rail">
                  <i class="drag-handle far fa-bars"></i>
                </span> -->
          <span class="text grey-00 margin-10 padding-10" v-show="!isSuccess"
            >Plus tard</span
          >
          <span class="text green margin-10 padding-10" v-show="isSuccess">Retour</span>
        </button>
      </router-link>

      

      <div class="flex-end" >
        <button v-if="getActiveEtape?.statutEtapeIDEid == 0" class="xs" type="button" @click="resetRdv()">
          <i class="fa fa-times"></i> <span>Effacer la date</span>
        </button>
      </div>

      
    </form>
  </div>
</template>



<script>
export default defineComponent({
  data() {
    return {
      isSuccess:false,
      dateRdv: useCalendarStore().getActiveEtape?.dateRDV,
    };
  },
  computed: {
    isDateSet() {
      return this.dateRdv != undefined;
    },
    isDateValid() {

      // console.log(useCalendarStore().getActiveEtape?.cibleMin);
      // console.log(useCalendarStore().getActiveEtape?.cibleMax);
      // console.log(moment(this.dateRdv).isBetween(
      //   moment(useCalendarStore().getActiveEtape?.cibleMin),
      //   moment(useCalendarStore().getActiveEtape?.cibleMax,
      //   undefined,
      //   '[]')));

        return moment(this.dateRdv).isSameOrBefore(useCalendarStore().getActiveEtape?.cibleMax)
        &&  moment(this.dateRdv).isSameOrAfter(useCalendarStore().getActiveEtape?.cibleMin);

      // return moment(this.dateRdv).isBetween(
      //   moment(useCalendarStore().getActiveEtape?.cibleMin),
      //   moment(useCalendarStore().getActiveEtape?.cibleMax,
      //   undefined,
      //   '[]')
      // );
    },
  },
  mounted: function () {
    this.dateRdv = useCalendarStore().getActiveEtape?.dateRDV;
  },
  methods: {
    updateRdvValue(event) {
      this.dateRdv = event.target.value;
    },
    setRdv() {
      useCalendarStore().setRdv(this.dateRdv);
      this.isSuccess = true;
      this.$router.push({ path: '/etape' });
    },
    resetRdv() {
      useCalendarStore().setRdv("");
      this.dateRdv = '';
      this.isSuccess = true;
      this.$router.push({ path: '/etape' });
    },
    setCurrentEtape() {
      let ref = this;
      ref.dateRdv = useCalendarStore().getActiveEtape?.dateRDV;
    },
  },
});
</script>
