import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";
import api from "../services/api";


export const useCalendarStore = defineStore({
    id: "calendar",
    state() {
        return {
            loading: false,
            error: null,
            rawItems: [],
            calendar: null,
            etapes: [],
            activeEtapeId: null,
            currentEtapeId: null
        }
    },
    getters: {
        getAppStatus(state) {
            return this.getCssStatus(state.getActiveEtape?.actes[0].status.state);
        },

        getActiveEtape(state) {
            return state.etapes.find(x => x.id === state.activeEtapeId);
        },

        getActiveEtapeRdvState(state) {
            return this.getActiveEtape?.actes[0].status.state;
        },

        getActiveEtapeTraitementState(state) {
            return this.getActiveEtape?.actes[1].status.state;
        },

        getActiveEtapeActeState(state) {
            return this.getActiveEtape?.actes[2].status.state;
        },

        items(state) {
            state.rawItems.push(1);
            state.rawItems.push(2);
            state.rawItems.push(3);
            return state.rawItems;
        }
    },
    actions: {
        setRdv(dateRDV) {
            let ref = this;
            ref.hasError = false;

            console.log('***', this.getActiveEtape);

            let data = {
                typeCohorteId: 1,
                calendrierId: this.getActiveEtape.calendarId,
                etapeId: this.getActiveEtape.id,
                acteId: this.getActiveEtape.actes[0].id,
                etapeDateRdv: dateRDV,
            };

            api
                .post("/calendar/SetRdv", data)
                //.then(r => r.json())
                .then((json) => {
                    //this.calendar[0] = json.data;

                    //getActiveEtape = this.calendar[0];

                    // document
                    //     .getElementById("appcontent")
                    //     ?.setAttribute(
                    //         "state",
                    //         this.$filters.getCssStatus(this.getActiveEtape.actes[0].status.state)
                    //     );
                    this.fetchCalendar(ref.activeEtapeId);
                    this.loading = false;
                    this.isSuccess = true;

                    return;
                })
                .catch(function (error) {
                    ref.hasError = true;
                    ref.errorMessage = error.message;

                    if (error.response) {
                        console.log("Error", error.message);
                        ref.errorMessage = error.response.data.errors;
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                });
        },
        setTraitement(hasTraitementGot) {
            let ref = this;
            ref.hasError = false;

            let data = {
                etapeId: this.getActiveEtape.id,
                acteId: this.getActiveEtape.actes[1].id,
                hasTraitementGot: hasTraitementGot,
            };

            api
                .post("/calendar/SetTraitement", data)
                //.then(r => r.json())
                .then((json) => {
                    //this.calendar[0] = json.data;

                    this.fetchCalendar(ref.activeEtapeId);
                    //getActiveEtape = this.calendar[0];
                    this.loading = false;
                    this.isSuccess = true;
                    return;
                })
                .catch(function (error) {
                    ref.hasError = true;
                    ref.errorMessage = error.message;

                    if (error.response) {
                        console.log("Error", error.message);
                        ref.errorMessage = error.response.data.errors;
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                });
        },
        setInjection(hasInjectionGot) {
            let ref = this;
            ref.hasError = false;

            let data = {
                etapeId: this.getActiveEtape.id,
                acteId: this.getActiveEtape.actes[2].id,
                hasInjectionGot: hasInjectionGot,
            };

            api
                .post("/calendar/SetInjection", data)
                //.then(r => r.json())
                .then((json) => {
                    //this.calendar[0] = json.data;

                    this.fetchCalendar(ref.activeEtapeId);
                    //getActiveEtape = this.calendar[0];
                    this.loading = false;
                    this.isSuccess = true;
                    // location.href='/etape';
                    return;
                })
                .catch(function (error) {
                    ref.hasError = true;
                    ref.errorMessage = error.message;

                    if (error.response) {
                        console.log("Error", error.message);
                        ref.errorMessage = error.response.data.errors;
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                });
        },
        getCssStatus(statusLevel) {
            let css = 'default';
            switch (statusLevel) {
                case -1:
                    css = 'complete';
                    break;
                case 0:
                    css = 'disabled';
                    break;
                case 1:
                    css = 'default';
                    break;
                case 2:
                    css = 'upcoming';
                    break;
                case 3:
                    css = 'warning';
                    break;
                case 4:
                    css = 'urgent';
                    break;
                case 5:
                    css = 'urgent';
                    break;
                default:
                    break;
            }
            return css;
        },

        goNextEtape() {
            let currentIndex = this.etapes.indexOf(this.etapes.find(x => x.id === this.activeEtapeId));

            if (currentIndex < this.etapes.length) {
                this.activeEtapeId = this.etapes[currentIndex + 1].id;
            }
        },


        goPreviousEtape() {
            let currentIndex = this.etapes.indexOf(this.etapes.find(x => x.id === this.activeEtapeId));

            if (currentIndex > 0) {
                this.activeEtapeId = this.etapes[currentIndex - 1].id;
            }
        },

        async fetchCalendar(forceActiveEtapeId) {
            this.etapes = []
            this.loading = true
            let ref = this;
            try {
                api
                    .get("/calendar/GetCalendar",
                        {
                            // params: {
                            //     typeCohorteId: 1,
                            //     calendrierId: 3
                            // }
                        }
                    )
                    //.then(r => r.json())
                    .then(json => {
                        ref.etapes = json.data;


                        for (let i = 0; i < ref.etapes.length; i++) {

                            if(ref.etapes[i].isComplete == false) {
                                forceActiveEtapeId = forceActiveEtapeId || ref.etapes[i].id;

                                ref.activeEtapeId = forceActiveEtapeId; 
                                ref.currentEtapeId = ref.activeEtapeId;
                                ref.loading = false;
                                return;
                            }
                          }


                        ref.activeEtapeId = forceActiveEtapeId || ref.etapes[0].id;
                        ref.currentEtapeId = ref.activeEtapeId;
                        ref.loading = false;
                        return;
                    });




            } catch (error) {
                this.error = error
            } finally {
                this.loading = false
            }
        },

        addItem(name) {
            this.rawItems.push(name);
        },

        removeItem() {
            this.rawItems.pop();
        },

        async purchaseItems() {
            const user = useAuthStore();
            if (!user.name) return;

            this.addItem(5);
            console.log("Items in calendar", this.items);
            const n = this.items.length;
            this.rawItems = [];

            return n;
        }
    }
});
