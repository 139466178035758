<template>
  <div class="post" :state="$filters.getCssStatus(calendarStore.getActiveEtapeTraitementState)">
    <div v-if="loading" class="loading">
      <!--Loading... Please refresh once the ASP.NET backend has started.-->
    </div>
    <!-- <div class="flex-middle-gap-20 margin-left-10  margin-bottom-10  margin-right-10">
      <i class="icon fas fa-home-alt bg-state white"></i>
      <h1 class="flexchild-100">
        <span class="weight-600">Récupérez votre traitement</span>
      </h1>
    </div> -->

    <form class="flex-column-gap-20 padding-10 rounded-30 bg-white shadow">
      <h3 class="flex-middle text-center min-height-70"><i class="icon fas fa-home-alt bg-state white"></i>&nbsp;&nbsp;Lorsque vous avez récupéré votre traitement, confirmez le ici.</h3>

      <button 
        v-if="this.calendarStore.getActiveEtape?.statutEtapeIDEid == 0"
        type="button"
        @click="setTraitement(true)"
        class="btn-confirm bg-darkstate bg-blue flex-center"
        successmessage="Action confirmée !"
        successaction="changeStateToComplete">
        <!-- <span class="drag-rail">
							<i class="drag-handle far fa-bars"></i>
						</span> -->
        <span class="text">Valider</span>
      </button>


      <router-link to="/etape">
        <button
          v-if="this.calendarStore.getActiveEtape?.statutEtapeIDEid == 0"
          type="button"
          :class="{
          'btn white back': true,
          'bg-grey-green': isSuccess,
          'bg-grey-03': !isSuccess,
          }"
          successmessage="Action annulée !"
          successaction=""
          style="width: 100%">
          <!-- <span class="drag-rail">
                  <i class="drag-handle far fa-bars"></i>
                </span> -->
          <span class="text grey-00 margin-10 padding-10" v-show="!isSuccess">Plus tard</span>
          <span class="text green margin-10 padding-10" v-show="isSuccess">Retour</span>
        </button>
      </router-link>


      <div class="flex-end"  >
        <!-- v-if="this.calendarStore.getActiveEtape?.actes[1].dateComplete != '' && this.calendarStore.getActiveEtape?.actes[1].dateComplete != null"> -->
        <button v-if="this.calendarStore.getActiveEtape?.statutEtapeIDEid == 0" class="xs" type="button" @click="setTraitement(false)">
          <i class="fa fa-times"></i> <span>Annuler la confirmation</span>
        </button>
      </div>


      <!-- <button>
						<i class="fas fa-clock"></i>
						<span>Plus tard</span>
					</button> -->
    </form>
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue';
import api from "../services/api";
import moment from 'moment'
import { useCalendarStore } from "../stores/calendar";

export default defineComponent({
  data() {
    return {
      loading: false,
      post: null,
      calendar: null,
      currentEtape: null,
      dateRdv: '',
      hasError: false,
      errorMessage: '',
      isSuccess: false,
      calendarStore:null
    };
  },
  components: {
  },
  computed: {
  },
  created() {
    this.calendarStore = useCalendarStore();
  },
  mounted() {

    //document.getElementsByTagName('main').setAttribute('state', this.$filters.getCssStatus(useCalendarStore().getActiveEtape?.actes[1].status.state));
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData',
    'calendar': 'setCurrentEtape'
  },
  methods: {
    setTraitement(hasTraitementGot) {
      useCalendarStore().setTraitement(hasTraitementGot);
      this.isSuccess=true;
      this.$router.push({ path: '/etape' });
    }
  },
});
</script>
