<template>
    <div>
      <div v-if="step === 1" class="flex-column-gap">
        <h1>Mot de passe oublié</h1>
        <label for="phoneNumber">Entrez votre numéro de téléphone mobile :</label>
        <input type="text" id="phoneNumber" v-model="phoneNumber" @input="validatePhoneNumber" placeholder="ex. : 0607080910" pattern="\d*"/>
        <p v-if="!phoneNumberValid">Téléphone à 10 chiffres svp.</p>
        <button @click="sendVerificationCode" :disabled="!phoneNumberValid" class="button bg-darkorange white hover-bg-orange">
                <span>Continuer</span></button>
      <label v-text="message"></label>

      </div>
      <div v-else-if="step === 2" class="flex-column-gap">  
        <h1>Saisie du code de validation</h1>
        <label for="verificationCode">Entrez le code reçu : </label>
        <input type="text" id="verificationCode" v-model="verificationCode" @input="validateVerificationCode" placeholder="ex. : 123456" pattern="\d{6}"/>
        <p v-if="!verificationCodeValid">Code de validation à 6 chiffres svp.</p>
        <button @click="verifyCode" :disabled="!verificationCodeValid" class="button bg-darkorange white hover-bg-orange">Continuer</button>
      <label v-text="message"></label>

      </div>
      <div v-else-if="step === 3" class="flex-column-gap">
        <h1>Saisie du nouveau mot de passe</h1>
        <label for="newPassword">Nouveau mot de passe :</label>
        <input type="password" id="newPassword" v-model="newPassword" @input="validatePassword" pattern="\d{8}" />
        <p v-if="!pwValid">Mot de passe à 8 chiffres svp.</p>
        <button @click="resetPassword" :disabled="!pwValid" class="button bg-darkgreen white hover-bg-green">Terminer</button>
      <label v-text="message"></label>

      </div>
      <div v-else-if="step === 4" class="flex-column-gap">
        <label v-text="message"></label>
        <button @click="this.$emit('done');" class="button bg-darkgreen white hover-bg-green">Retour à l'écran de connexion</button>
      </div>
    </div>
  </template>
  <script>

  import api from "../services/api";
  import { defineComponent } from 'vue';

  export default defineComponent({
    props:{
        cid: {
            type : [String],
            required: true
        }
    },
    data() {
      return {
        step: 1,
        phoneNumber: '',
        verificationCode: '',
        newPassword: '',
        message: '',
        pwValid: true,
        phoneNumberValid: false,
        verificationCodeValid: false
      };
    },
    methods: {
      validatePassword() {
        const digitsRegex = /^\d{8}$/;
        this.pwValid = digitsRegex.test(this.newPassword);

        const passwordInput = document.getElementById('newPassword');
        passwordInput.addEventListener('input', () => {
        // Show the password briefly for 1 second
        passwordInput.type = 'text';
        setTimeout(() => {
            // After 1 second, hide the password by changing the type back to 'password'
            passwordInput.type = 'password';
        }, 850);
        });

      },
      validatePhoneNumber() {
        const digitsRegex = /^\d{10}$/;
        this.phoneNumberValid = digitsRegex.test(this.phoneNumber);

      },
      validateVerificationCode() {
        const digitsRegex = /^\d{6}$/;
        this.verificationCodeValid = digitsRegex.test(this.verificationCode);

      },
      sendVerificationCode() {

        if(this.phoneNumberValid) {

        // Assuming you have an API endpoint to send the code via SMS
        // Make an API call to send the code to the provided email
        // Example API call using axios:
        api.post('/Security/requestPasswordChange', { cid: this.cid, phoneNumber: this.phoneNumber })
            .then(response => { 
                console.log('requestPasswordChange result', response);
            if (response.status == 200) {    
                this.message = 'Un code de confirmation vous a été envoyé.';
                this.step = 2; // Move to the next step to verify the code
            } else {
                this.message = 'Le code de confirmation n\'a pas pu être envoyé. ' + response.data;
            }
            })
            .catch(error => {
            this.message = 'Une erreur s\'est produite, veuillez réessayer svp.';
            });
        }

      },
      verifyCode() {
        // Here you would implement the logic to verify the entered verification code
        // If the code is correct, move to the next step

        this.message = 'Code de validation saisi.';


        this.step = 3;
      },
      resetPassword() {
        // Here you would implement the logic to reset the password
        // Once the password is reset, you can display a success message or redirect to another page
        // For this example, we will log the new password to the console

        if(this.newPassword.length != 8 || this.newPassword == '00000000') { //TODO : Enforce pw strength on client side
            this.message = 'Mot de passe trop court.';
        } else {
            api.post('/Security/Chpwd', { cid: this.cid, token: this.verificationCode, newPwd: this.newPassword})
                .then(response => { 
                if (response.status == 200) {    
                    this.message = 'Votre mot de passe a bien été changé !';
                    this.step = 4;
                } else {
                    this.message = 'Votre mot de passe n\'a pu être changé. Veuillez réessayer svp. ' + response.data;
                }
                })
                .catch(error => {
                this.message = 'Une erreur s\'est produite, veuillez réessayer svp.';
            });
        }
      }
    }
  });
  </script>