import { createApp } from 'vue'
import { createPinia } from 'pinia'
// import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
// import Login from './components/Login.vue'
// import Etape from './components/Etape.vue'
// import Error from './components/Error.vue'
import { useAuthStore } from "./stores/auth.store";
import { useCalendarStore } from "./stores/calendar";
import router from "./router/router";

//createApp(App).mount('#app')
import moment from 'moment'
import 'moment/locale/fr'

const pinia = createPinia()

const app = createApp(App)
    .use(pinia)
    .use(router)
    .use(useAuthStore())
    .use(useCalendarStore())


app.config.globalProperties.$logo = process.env.VUE_APP_LOGO;

app.config.globalProperties.$filters = {
    test() { return 'plop'; },
    formatDate(value) {
        let d = moment(String(value));
        return moment(d).format('DD/MM/YYYY')
    },
    formatDateLong(value) {
        let d = moment(String(value));
        return moment(d).format('ddd DD MMM YYYY')
    },
    formatDateInput(value) {
        let d = moment(String(value));
        return moment(d).format('YYYY-MM-DD')
    },
    getCssStatus(statusLevel) {
        let css = 'default';
        switch (statusLevel) {
            case -1:
                css = 'complete';
                break;
            case 0:
                css = 'disabled';
                break;
            case 1:
                css = 'default';
                break;
            case 2:
                css = 'upcoming';
                break;
            case 3:
                css = 'warning';
                break;
            case 4:
                css = 'urgent';
                break;
            case 5:
                css = 'urgent';
                break;
            default:
                break;
        }
        return css;
    }
}
app.mount('#app')



//moment.locale('fr').format('L');

